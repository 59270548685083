
:root {
  font-family: 'KinfolkReg', Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  touch-action: none;
}

#root{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}


body, html {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overscroll-behavior: none;
  user-select: none;
  overflow: hidden;
}